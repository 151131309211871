<template>
  <div class="lab-results box-page-new">
    <div class="tab__menus">
      <tabs-menu>
        <template v-slot:header>
          <div class="tabs-link active">
            <li>{{ $t("summary") }}</li>
          </div>
          <router-link to="/patient/lab-result/history" class="tabs-link">
            <li>{{ $t("history") }}</li>
          </router-link>
        </template>
      </tabs-menu>
    </div>
    <div
      class="tab__contents"
      :class="{ 'tab__contents--active': activeTab == 1 }"
    >
      <div class="tab__selects">
        <v-select
          @change="currentDataItems"
          v-model="activeTab"
          :items="listTab"
          item-text="title"
          item-value="id"
          dense
        />
      </div>
    </div>
    <div>
      <v-row class="content-table">
        <v-col cols="12">
          <div class="mb-4 d-flex">
            <h4 class="mr-4">{{ $t("LatestTestResults") }}</h4>
            <p class="now">{{ now }}</p>
          </div>
          <v-row>
            <v-col
              cols="12"
              md="6"
              lg="4"
              v-for="(data, index) of dataCard"
              :key="index"
            >
              <CardLabCheck :data="data" />
            </v-col>
          </v-row>
          <div class="show-more">
            <p></p>
          </div>
          <div class="mt-10 mb-4">
            <h4>{{ $t("TestResultsSummary") }}</h4>
          </div>
          <v-row>
            <v-col cols="4" md="2">
              <label for="">{{ $t("last") }}</label>
              <v-select
                :items="limit"
                v-model="selectedLimit"
                outlined
                @change="getLastChart"
                dense
              ></v-select>
            </v-col>
            <v-col>
              <div class="filter-date">
                <v-dialog
                  ref="dialog"
                  v-model="modalStartDate"
                  :return-value.sync="startDate"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div class="box-date mr-7">
                      <p class="label-filter">{{ $t("start_date") }}</p>
                      <input
                        type="text"
                        class="label-date"
                        v-bind="attrs"
                        v-on="on"
                        readonly
                        v-model="dateFormatedStart"
                      />
                    </div>
                  </template>
                  <v-date-picker
                    :locale="$i18n.locale"
                    v-model="startDate"
                    :max="
                      new Date(
                        new Date(endDate != '' ? endDate : null) -
                          new Date().getTimezoneOffset() * 60000
                      )
                        .toISOString()
                        .substr(0, 10)
                    "
                    no-title
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modalStartDate = false">
                      {{ $t("cancel") }}
                    </v-btn>
                    <v-btn text color="primary" @click="changeFilterDate">
                      {{ $t("ok") }}
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
                <v-dialog
                  ref="dialogend"
                  v-model="modalEndDate"
                  :return-value.sync="endDate"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div class="box-date mr-7">
                      <p class="label-filter">{{ $t("end_date") }}</p>
                      <input
                        type="text"
                        class="label-date"
                        v-bind="attrs"
                        v-on="on"
                        readonly
                        v-model="dateFormatedEnd"
                      />
                    </div>
                  </template>
                  <v-date-picker
                    :locale="$i18n.locale"
                    :max="
                      new Date(
                        Date.now() - new Date().getTimezoneOffset() * 60000
                      )
                        .toISOString()
                        .substr(0, 10)
                    "
                    :min="
                      new Date(
                        new Date(startDate != '' ? startDate : null) -
                          new Date().getTimezoneOffset() * 60000
                      )
                        .toISOString()
                        .substr(0, 10)
                    "
                    v-model="endDate"
                    no-title
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modalEndDate = false">
                      {{ $t("cancel") }}
                    </v-btn>
                    <v-btn text color="primary" @click="changeFilterDate">
                      {{ $t("ok") }}
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </div>
            </v-col>
          </v-row>

          <v-row v-if="dataChart.length > 0">
            <div>
              <v-row>
                <template v-for="(chart, index) of dataChart">
                  <v-col cols="12" md="6" lg="4" :key="index">
                    <div class="card-chart">
                      <h5 class="mb-5">
                        {{ chart.title }}
                      </h5>
                      <!-- <chart-summary :data="chart.data" /> -->
                    </div>
                  </v-col>
                </template>
              </v-row>
            </div>
          </v-row>
          <p v-else>{{ $t("not_yet_test") }}</p>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
// import TabContent from "@/components/v2/tabs/index.vue";
import API from "@/service/api_service.js";
import Auth from "@/service/auth_service";
import TabsMenu from "@/components/general/TabsMenu.vue";
import CardLabCheck from "@/components/general/CardLabCheck.vue";

export default {
  components: { TabsMenu, CardLabCheck },
  data: () => ({
    activeTab: 1,
    listTab: [
      {
        id: 1,
        title: "Summary",
      },
      {
        id: 2,
        title: "History",
      },
    ],
    modalStartDate: false,
    modalEndDate: false,
    // MMDDYYY
    startDate: "",
    endDate: "",
    limit: [5, 10, 20, "ALL"],
    now: "",
    selectedLimit: 5,
    dataCard: [],
    dataChart: [],
    chartData: {
      datasets: [
        {
          fill: false,
          data: [],
          borderColor: "rgb(75, 192, 192)",
          spanGaps: true,
          parsing: {
            xAxisKey: "TestDate",
            yAxisKey: "Result",
          },
        },
      ],
    },
  }),
  computed: {
    dateFormatedStart() {
      return this.formatDate(this.startDate);
    },
    dateFormatedEnd() {
      return this.formatDate(this.endDate);
    },
  },
  async mounted() {
    this.$store.commit("setLoading", true);
    await API.post(
      `${process.env.VUE_APP_API_BIZNET_URL}virtuelab/labres`,
      {
        "Content-Type": "application/json",
        "V-TOKEN": Auth.getToken(),
      },
      { param1: "card", param2: null, param3: null, param4: null }
    ).then(res => {
      if (res.status) {
        this.dataCard = res.data;
      }
    });
    this.now = this.formatDate(new Date());
    this.startDate = new Date(
      `${new Date().getFullYear()}-${
        new Date().getMonth() + 1
      }-${new Date().getDate()}`
    )
      .toISOString()
      .substr(0, 10);
    this.endDate = new Date().toISOString().substr(0, 10);
    await this.getLastChart();
    this.$store.commit("setLoading", false);
  },
  methods: {
    currentDataItems() {
      if (this.activeTab == 1) {
        this.$router.push("/patient/lab-result");
      } else if (this.activeTab == 2) {
        this.$router.push("/patient/lab-result/history");
      }
    },
    getLastChart() {
      if (this.selectedLimit == "ALL") {
        this.selectedLimit = 250;
      }

      API.post(
        `${process.env.VUE_APP_API_BIZNET_URL}virtuelab/labres`,
        {
          "Content-Type": "application/json",
          "V-TOKEN": Auth.getToken(),
        },
        {
          param1: "last",
          param2: this.selectedLimit,
          param3: null,
          param4: null,
        }
      ).then(async res => {
        if (res.status) {
          res.data.sort((a, b) => {
            let _a = new Date(a.TestDate);
            let _b = new Date(b.TestDate);
            return _a === _b ? 0 : _a > _b ? 1 : -1;
          });
          this.dataChart = [];
          const testGrouped = this.grouped(res.data, "Test");
          await Object.keys(testGrouped).forEach(key => {
            this.dataChart = [
              ...this.dataChart,
              { title: key, data: testGrouped[key] },
            ];
          });
        }
      });
    },
    getDateChart() {
      API.post(
        `${process.env.VUE_APP_API_BIZNET_URL}virtuelab/labres`,
        {
          "Content-Type": "application/json",
          "V-TOKEN": Auth.getToken(),
        },
        {
          param1: "linechart",
          param2: this.startDate,
          param3: this.endDate,
          param4: null,
        }
      ).then(async res => {
        if (res.status) {
          this.dataChart = [];
          const testGrouped = this.grouped(res.data, "Test");
          await Object.keys(testGrouped).forEach(key => {
            this.dataChart = [
              ...this.dataChart,
              { title: key, data: testGrouped[key] },
            ];
          });
        }
      });
    },
    grouped(xs, key) {
      return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    },
    formatDate(param) {
      if (!param) return null;
      const date = new Date(param);
      const MMM = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "June",
        "July",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
      ];
      return `${date.getDate()} ${MMM[date.getMonth()]} ${date.getFullYear()}`;
    },
    changeFilterDate() {
      this.$refs.dialog.save(this.startDate);
      this.$refs.dialogend.save(this.endDate);
      this.getDateChart();
      this.modalStartDate = false;
      this.modalEndDate = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/custom.scss";
.box-page-new {
  margin: 15px 24px;
}
.tabs {
  background: #e5e5e5 !important;
}
.content-table {
  padding: 35px 18px 18px 18px;
  @media screen and (min-width: 960px) {
    padding: 35px 18px 18px 36px;
  }
  @media screen and (max-width: 958px) {
    padding: 5px 18px 18px 18px;
  }
}
.lab-results {
  .now {
    color: #5353534d;
    font-size: 14;
    font-weight: 400;
  }
  h4 {
    color: $blue_1;
  }
  .card-chart {
    padding: 22px;
    color: $gray_1;
    border: 1px solid #c2c2c2;
    border-radius: 18px;
    box-shadow: 0px -2px 18px rgba(0, 0, 0, 0.04);
  }
  .filter-date {
    border-radius: 6px;
    background: #f8f8f8;
    padding: 12px 16px;
    float: right;
    .box-date {
      display: inline-block;
      cursor: pointer;
      .label-filter {
        margin: 0;
        font-size: 12px;
        color: #93969b;
        font-weight: 400;
      }
      .label-date {
        margin: 0;
        font-size: 16px;
        color: #535353;
        width: 95px;
        &:focus {
          outline: none;
        }
      }
    }
  }
  .show-more {
    background: #d3dff045;
    border-radius: 6px;
    margin: 20px 0;
    height: 30px;
  }
}
</style>

<template>
  <div class="card" :class="{ purple: data.ColorFlag == 1 }">
    <v-row>
      <v-col cols="2">
        <IconCardLabCheck />
      </v-col>
      <v-col cols="6">
        <h4>{{ data.Test }}</h4>
        <span class="count">{{ data.Result }}</span>
        <span class="sat">mmHG</span>
      </v-col>
      <v-col cols="4">
        <slider-result
          :data="{
            max: data.MaximumValue,
            min: data.MinimumValue,
            result: data.Result,
          }"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import IconCardLabCheck from "../elements/IconCardLabCheck.vue";
import SliderResult from "./SliderResult.vue";
export default {
  components: { IconCardLabCheck, SliderResult },
  props: { data: Object, default: () => {} },
};
</script>

<style lang="scss" scoped>
.card {
  color: white;
  padding: 18px;
  border-radius: 18px;
  box-shadow: 0px 4px 17.81px rgba(46, 193, 177, 0.25);
  background: radial-gradient(
    87.9% 175.8% at 8.45% -3.79%,
    #49dd81 0%,
    #22b4c6 100%
  );
  &.purple {
    background: radial-gradient(
      89.65% 179.3% at 4.23% 0%,
      #a855f7 0%,
      #6366f1 100%
    );
  }

  h4 {
    font-size: 16px;
  }
  .count {
    font-size: 24px;
    font-weight: 700;
  }
  .sat {
    font-size: 12px;
    font-weight: 700;
    margin-left: 16px;
  }
}
</style>
